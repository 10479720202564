import React, { useState, useEffect } from "react";
import "./Order.css";
import { open_api_with_user_token } from "../../Utils/network";
import LoadingOverlay from "../Dialog/LoadingOverlay";
import emptyBox from "../../Assets/Logo_icons/emptybox.svg";
import {
  NoPackagesContainer,
  NoPackagesImage,
  NoPackagesText,
} from "../../Components/Dialog/PaymentDialogStyles";
import { fetchCountryCode } from "../Dialog/helper";
import { Button } from "@mui/material";
import { getCountryCode } from "../../Utils/network";
import { useNavigate, useLocation } from "react-router-dom";
import OrderCard from "../Card/OrderCard";

const Order = () => {
  const location = useLocation();
  const { selectionType } = location.state || {};
  const [ordersData, setOrdersData] = useState([]);
  const [selectedType, setSelectedType] = useState(selectionType || "All");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [countryCode, setCountryCode] = useState("in");
  const itemsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCountry = async () => {
      const countryCode = await getCountryCode();
      setCountryCode(countryCode);
    };
    fetchCountry();
  }, []);

  const priceSymbol = countryCode === "in" ? "₹" : "$";
  const isUSD = countryCode === "in" ? "INR" : "USD";

  useEffect(() => {
    const token = localStorage.getItem("bearerToken");
    const fetchOrdersData = async () => {
      try {
        setIsLoading(true);
        const response = await open_api_with_user_token(token).get(
          "payment/history?page=1&size=10"
        );
        if (Array.isArray(response.data.data)) {
          setOrdersData(response.data.data);
        } else {
          console.error("Fetched data is not an array:", response.data.data);
          setOrdersData([]);
        }

        setTotalPages(response.max_pages || 1);
      } catch (error) {
        console.error("Failed to fetch orders data", error);
        setOrdersData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrdersData();
  }, [currentPage]);

  const filterOrders = () => {
    if (selectedType === "All") {
      return ordersData;
    } else if (selectedType === "Workshop") {
      return ordersData.filter((order) => order.workshop_purchase !== null);
    } else if (selectedType === "Session") {
      return ordersData.filter((order) => order.session_purchase !== null);
    }
    return ordersData;
  };

  console.log(filterOrders());
  const filteredOrders = filterOrders();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOrders = filteredOrders.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (isLoading) {
    return <LoadingOverlay></LoadingOverlay>;
  }

  if (ordersData.length === 0) {
    return (
      <NoPackagesContainer>
        <NoPackagesImage src={emptyBox} alt="No orders available" />
        <NoPackagesText>No orders yet</NoPackagesText>
      </NoPackagesContainer>
    );
  }

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "failed":
        return "#ffcccc";
      case "paid":
        return "#ccffcc";
      case "pending":
        return "#ffffcc";
      default:
        return "#f0f0f0";
    }
  };

  const StatusChip = ({ status }) => (
    <span
      className="status-chip"
      style={{
        backgroundColor: getStatusColor(status),
        padding: "4px 8px",
        borderRadius: "12px",
        marginLeft: "8px",
        fontSize: "0.8em",
        color: "#333",
      }}
    >
      {status}
    </span>
  );

  const handleOrderClick = (id) => {
    navigate(`/profile/orders/${id}`);
  };

  return (
    <div className="orders-page">
      <div className="orders-header">
        <h2>Orders</h2>
      </div>

      <div className="order-filters">
        <button
          className={`chip ${selectedType === "All" ? "active" : ""}`}
          onClick={() => {
            setSelectedType("All");
            setCurrentPage(1);
          }}
        >
          All
        </button>
        <button
          className={`chip ${selectedType === "Workshop" ? "active" : ""}`}
          onClick={() => {
            setSelectedType("Workshop");
            setCurrentPage(1);
          }}
        >
          Workshop
        </button>
        <button
          className={`chip ${selectedType === "Session" ? "active" : ""}`}
          onClick={() => {
            setSelectedType("Session");
            setCurrentPage(1);
          }}
        >
          Session
        </button>
      </div>

      {currentOrders.length === 0 ? (
        <NoPackagesContainer>
          <NoPackagesImage src={emptyBox} alt="No orders available" />
          <NoPackagesText>No orders yet</NoPackagesText>
        </NoPackagesContainer>
      ) : (
        <>
          <div className="orders-list">
            {currentOrders.map((order, index) => (
              // <div
              //   key={`${order?.id}-${index}`}
              //   className="order-card"
              //   onClick={() => handleOrderClick(order?.id)}
              // >
              //   <div className="order-info">
              //     <div className="order-id">
              //       Order ID: {order?.id}{" "}
              //       <StatusChip status={order?.payment_status_str} />
              //     </div>
              //     <div className="order-name">
              //       {order?.workshop_purchase
              //         ? `Workshop Name: ${order?.workshop_purchase?.workshop?.title}`
              //         : `Session Package: ${order?.session_purchase?.package?.title}`}
              //     </div>
              //     <div className="order-remaining">
              //       {order?.workshop_purchase
              //         ? ""
              //         : `Remaining sessions: ${order?.session_purchase?.remaining_sessions}`}
              //     </div>
              //   </div>
              //   <div className="order-meta">
              //     <div className="order-date">{order?.date_created}</div>
              //     <div className="order-price">
              //       {priceSymbol}
              //       {order?.workshop_purchase
              //         ? order?.order?.currency === "INR"
              //           ? order?.workshop_purchase?.total_pay?.toFixed(2)
              //           : order?.workshop_purchase?.total_pay?.toFixed(2)
              //         : order?.order?.currency === "INR"
              //           ? order?.session_purchase?.total_pay?.toFixed(2)
              //           : order?.session_purchase?.total_pay?.toFixed(2)}
              //     </div>
              //   </div>
              // </div>
              <>
                {order.workshop_purchase != null ? (
                  <>
                    <OrderCard
                      key={`${order?.id}-${index}`}
                      orderDate={order?.date_created}
                      total={`${priceSymbol} ${order?.order?.currency === "INR" ? order?.workshop_purchase?.total_pay?.toFixed(2) : order?.workshop_purchase?.total_pay?.toFixed(2)}`}
                      shipTo={`${order?.workshop_purchase?.user_detail?.first_name} ${order?.workshop_purchase?.user_detail?.last_name}`}
                      orderNumber={order?.id}
                      orderedItemTitle={
                        order?.workshop_purchase?.workshop?.title
                      }
                      orderedItemImage={
                        order?.workshop_purchase?.workshop?.file
                      }
                      isWorkshop={true}
                      workshopSlug={order?.workshop_purchase?.workshop?.slug}
                    />
                  </>
                ) : (
                  <>
                    <OrderCard
                      key={`${order?.id}-${index}`}
                      orderDate={order?.date_created}
                      total={`${priceSymbol} ${order?.order?.currency === "INR" ? order?.session_purchase?.total_pay?.toFixed(2) : order?.session_purchase?.total_pay?.toFixed(2)}`}
                      shipTo={`${order?.session_purchase?.user_detail?.first_name} ${order?.session_purchase?.user_detail?.last_name}`}
                      orderNumber={order?.id}
                      orderedItemTitle={order?.session_purchase?.package?.title}
                      isWorkshop={false}
                      remainingSession={
                        order?.session_purchase?.remaining_sessions
                      }
                      order={order}
                    />
                  </>
                )}
              </>
            ))}
          </div>

          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                className={`page-button ${currentPage === index + 1 ? "active" : ""}`}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Order;

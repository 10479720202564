import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { open_api } from "../../Utils/network";
import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate from react-router-dom

// Import your assets
import contentBackground from "../../Assets/popup/designCurve.svg"; // Adjust the path
import logo from "../../Assets/popup/myburgoLogo.svg"; // Adjust the path
import { PhoneInput } from "react-international-phone";
import { getCountryCode } from "../../Utils/network";
import {
  handlePhoneNumberChange,
  validatePhoneNumber,
  addAlert,
  removeAlert,
} from "../Dialog/helper";
import CustomAlert from "../AlertDialog/CustomAlert";
import { red } from "@mui/material/colors";

const ContentContainer = styled.div`
  background-size: cover;
  color: black;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    border-radius: 0 0 10px 10px;
  }
`;

const Logo = styled.div`
  width: 43%;
  height: 15%;
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 60%;
    height: 10%;
    margin-bottom: 10px;
  }
`;

const Title = styled.div`
  font-size: 25px;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const Form = styled.form`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;

  @media (max-width: 768px) {
    width: 90%;
    padding: 15px;
  }
`;

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background: #f27497;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: medium;
  font-weight: 700;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
`;

const ErrorMessage = styled.div`
  color: #ff0000;
  font-size: 12px;
  margin-top: -5px;
  margin-bottom: 10px;
`;

const CustomPhoneInput = styled(PhoneInput)`
  flex: 1;
  .react-international-phone-input-container {
    display: flex;
    align-items: center;
    border: none; /* Remove internal borders */
    padding: 0;
    height: 40px; /* Set a height that matches the container */
    background: none; /* No background to blend with container */
  }

  .react-international-phone-input {
    border: none;
    outline: none;
    width: 100%;
    padding: 8px;
    font-size: 13px;
  }

  .react-international-phone-country-selector {
    border-right: 1px solid #ddd;
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    height: 100%;
    border-radius: 8px 0 0 8px;
  }
`;

export const OtpContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0;
  width: 100%;
`;

export const SuccessMessage = styled.div`
  text-align: center;
  margin-top: 20px;
  color: #2563eb;
  font-size: 18px;
`;

const EbookDialogContent = ({ ebookData, hideBg }) => {
  const [errors, setErrors] = useState({});
  const { expertslug, itemSlug } = useParams();
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState("in");
  const [isdCode, setIsdCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberWithCountryCode, setPhoneWithCountryCode] = useState("");
  const [countrycodeISO, setcountrycodeISO] = useState("IN");
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone: null,
    type: ebookData.type || itemSlug,
    expert: ebookData.expert,
    countrycode: 91,
  });

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
  };

  useEffect(() => {
    const fetchCountry = async () => {
      const countryCode = await getCountryCode();
      setCountryCode(countryCode);
    };
    fetchCountry();
  }, []);

  const handlePhoneInputChange = (value, data) => {
    const sanitizedPhoneValue = data?.inputValue
      ? data.inputValue.replace(/[^0-9]/g, "")
      : "";
    handlePhoneNumberChange(
      sanitizedPhoneValue,
      data,
      setIsdCode,
      setPhoneNumber,
      setPhoneNumberError,
      setPhoneWithCountryCode
    );

    setUserDetails((prevDetails) => ({
      ...prevDetails,
      phone: sanitizedPhoneValue,
    }));
  };

  const downloadEbook = () => {
    if (ebookData.ebook) {
      fetch(ebookData.ebook)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          const fileName = ebookData.ebook.split("/").pop() || "ebook.pdf";
          link.setAttribute("download", fileName);

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          window.URL.revokeObjectURL(url);
          setIsDownloaded(true);
        })
        .catch((error) => {
          console.error("Error downloading the file:", error);
        });
    } else {
      console.error("Ebook URL is not available");
    }
  };

  const handleSubmit = (e) => {
    userDetails.phone = phoneNumber;
    userDetails.countrycode = isdCode;
    e.preventDefault();
    let newErrors = {};
    if (!validateEmail(userDetails.email)) {
      newErrors.email = "Please enter a valid email address";
    }
    let isPhoneValid = validatePhoneNumber(
      phoneNumberWithCountryCode,
      setPhoneNumberError,
      setShowAlert,
      handleAddAlert,
      setcountrycodeISO
    );
    if (!isPhoneValid) {
      newErrors.phone = "Please enter a valid phone number";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setErrors({});
    open_api
      .post("/lead/leads/", userDetails)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          setUserDetails({
            name: "",
            email: "",
            phone: null,
          });
          downloadEbook();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re =
      /^(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})$/;
    return re.test(String(phone));
  };

  return (
    <ContentContainer hideBg={hideBg}>
      <Logo />
      <Title>{ebookData.title}</Title>

      {!isDownloaded ? (
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="name"
            placeholder="Name"
            value={userDetails.name}
            onChange={handleChange}
            required
          />
          <Input
            type="email"
            name="email"
            placeholder="Email"
            value={userDetails.email}
            onChange={handleChange}
            required
          />
          {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
          <OtpContainer
            style={{
              borderColor: phoneNumberError ? "red" : "#ccc",
            }}
          >
            <CustomPhoneInput
              defaultCountry={countryCode || "us"} // Ensure a default is always present
              value={phoneNumber || ""} // Use state-controlled phoneNumber to ensure consistency
              disableDialCodeAndPrefix={true}
              onChange={handlePhoneInputChange}
              placeholder="Enter your phone number"
              required
            />
          </OtpContainer>
          {/* <Input
          type="tel"
          name="phone"
          placeholder="Phone Number"
          value={userDetails.phone}
          onChange={handleChange}
          required
        /> */}
          {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
          {errors.submit && <ErrorMessage>{errors.submit}</ErrorMessage>}
          <Button type="submit">Download the ebook</Button>
          <h5 style={{ fontWeight: 400 }}>
            By signing up, you'll receive communications from myburgo. For more
            information, see our{" "}
            <a
              href="https://www.myburgo.com/terms-and-condition"
              target="_blank"
              style={{ color: "#2563eb" }}
            >
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a
              href="https://www.myburgo.com/privacy-policy"
              target="_blank"
              style={{ color: "#2563eb" }}
            >
              Privacy Policy
            </a>
            .
          </h5>
        </Form>
      ) : (
        <SuccessMessage>
          <p>Your ebook will be downloaded shortly!</p>
          <p>You can find it in your downloads folder.</p>
          <h6 style={{ color: "red" }}>
            If you were not able to download it please click download again to
            download the ebook
          </h6>
          <Button style={{ marginBottom: "10px" }} onClick={downloadEbook}>
            Download Again
          </Button>
          <Button
            style={{ backgroundColor: "#8866ff" }}
            onClick={() => {
              if (expertslug) {
                navigate(`/experts/${expertslug}`);
              }
            }}
          >
            Take me to Expert Page
          </Button>
        </SuccessMessage>
      )}

      {showAlert &&
        alerts.map((alert) => (
          <CustomAlert
            key={alert.id}
            title={alert.title}
            message={alert.message}
            onClose={() => handleRemoveAlert(alert.id)}
            autoClose={alert.autoClose}
            type={alert.type}
          />
        ))}
    </ContentContainer>
  );
};

export default EbookDialogContent;

import React from "react";
import "./PageNotFound.css";
import pageNotFoundImage from "../../Assets/404/pagenotfound.png";

const PageNotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-text">
        <h1>404</h1>
        <h2>Page not found</h2>
        <p>The page you are looking for is either broken or has been removed</p>
      </div>
      <div className="not-found-image">
        <img src={pageNotFoundImage} alt="Page not found" />
      </div>
    </div>
  );
};

export default PageNotFound;

import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BlogsCard from '../BlogsCard/BlogsCard';
import styles from "./BlogSlider.module.css";
import { open_api } from '../../Utils/network';


function BlogSlider() {
    const [blogs, setBlogs] = useState([])

    useEffect(() => {
        getBlogDetails();
    }, []);



    const getBlogDetails = () => {
        open_api
            .get(`blog/blog_pages?fields=id%2Ctitle%2Cpublished_date%2Cintro%2Cheader_image_url%2Ctags%2Cread_time%2Cauthors&type=blogs.BlogPage`)
            .then((res) => {
                // console.log(res?.data?.items, "getBlogDetails");
                if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
                    setBlogs(res?.data?.items)

                }
            })
            .catch((err) => {
                console.log(err);
            });
    };





    function CustomPrevArrow(props) {
        const { onClick } = props;
        return (
            <button
                // className="custom-arrow-banner custom-prev-arrow-banner"
                className={`${styles.customarrow} ${styles.customprevarrow}`}
                onClick={onClick}
            >
                <ArrowBackIosNewIcon /> {/* Material-UI ArrowBack icon */}
            </button>
        );
    }








    function CustomNextArrow(props) {
        const { onClick } = props;
        return (
            <button
                // className="custom-arrow-banner custom-next-arrow-banner"
                className={`${styles.customarrow} ${styles.customnextarrow}`}
                onClick={onClick}
            >
                <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
            </button>
        );
    }

    var settings = {
        className: styles.slickstyles,
        // dots: true,
        autoplay: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: false,
        arrows: false,
        // fade: true,
        autoplaySpeed: 3000,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1140,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };
    return (
        <div className={styles.container}>
            <Slider {...settings}>
                {blogs?.map((item) => {
                    // console.log(item , "089798uio");
                    return (
                        <BlogsCard  data={item}/>
                    )
                })}
            </Slider>
        </div>
    )
}

export default BlogSlider
const data = [
    {
        id: 1
    },
    {
        id: 2
    },
    {
        id: 3
    },
    {
        id: 4
    }
]
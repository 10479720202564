import React, { useState } from "react";
import { Star } from "lucide-react";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";

const CoachingCard = ({
  rating,
  tag,
  title,
  expertSlug,
  subtitle,
  duration,
  sessionType,
  priceSymbol,
  price,
  sessionCount,
  remainingSession,
  bypassPurchase,
}) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false); // State to handle expansion

  const handleToggleReadMore = () => {
    setIsExpanded((prev) => !prev); // Toggle the expanded state
  };

  const handlePackageChange = () => {
    navigate(`/bookings/${expertSlug}`);
    window.location.reload();
  };

  // Function to truncate the subtitle to the first 15 words
  const truncateSubtitle = (text, wordLimit) => {
    const wordsArray = text.split(" ");
    if (wordsArray.length > wordLimit) {
      return wordsArray.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (
    <div className={styles.coachingCard}>
      <div className={styles.cardHeader}>
        <div className={styles.rating}>
          <Star className={styles.starIcon} />
          <span>{rating}/5</span>
        </div>
        {tag && <span className={styles.tag}>{tag}</span>}
      </div>

      <h2 className={styles.title}>{title}</h2>

      {/* Truncate the subtitle and add "Read more" */}
      {subtitle && (
        <p className={styles.subtitle}>
          {isExpanded
            ? subtitle // Show full subtitle if expanded
            : truncateSubtitle(subtitle, 15)}{" "}
          {/* Show truncated version otherwise */}
          {subtitle.split(" ").length > 15 && (
            <span
              className={styles.readMore}
              onClick={handleToggleReadMore}
              style={{
                cursor: "pointer",
                color: "#2563eb",
                marginLeft: "5px",
              }}
            >
              {isExpanded ? "Show less" : "Read more"} {/* Toggle text */}
            </span>
          )}
        </p>
      )}

      <div className={styles.cardFooter}>
        <div className={styles.sessionInfo}>
          <svg
            className={styles.calendarIcon}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            ></path>
          </svg>
          <div className={styles.sessionInfoFooter}>
            <span>{duration}</span>
            <span className={styles.sessionType}>{sessionType}</span>
          </div>
        </div>

        {sessionCount ? (
          <>
            {bypassPurchase ? (
              <>
                <div className={styles.sessionCountContainer}>
                  <span>Remaining Sessions: {remainingSession}</span>
                </div>
              </>
            ) : (
              <>
                <div className={styles.sessionCountContainer}>
                  <span>{sessionCount}</span>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {" "}
            <div
              className={styles.priceButton}
              onClick={() => navigate(`/bookings/${expertSlug}`)}
            >
              {priceSymbol}
              <span>{price}</span>
              <svg
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                ></path>
              </svg>
            </div>
          </>
        )}
      </div>
      {sessionCount && !bypassPurchase ? (
        <>
          <div className={styles.changePackage} onClick={handlePackageChange}>
            Change Package
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CoachingCard;

import React, { useEffect, useState } from "react";
import styles from "./BlogInfo.module.css";
import innerBLogBaby from "../../Assets/innerBLogBaby.png";
import infoImg from "../../Assets/infoImg.png";
import { useParams } from "react-router-dom";
import { open_api } from "../../Utils/network";
import { Helmet } from "react-helmet";
import { FaFacebook, FaTwitter, FaLinkedin, FaShareAlt } from "react-icons/fa";
import ArticleHeader from "./ArticleHeader";

function BlogInfo({ data }) {
  const [blogReviews, setBlogReviews] = useState();
  const [blogMain, setBlogMain] = useState();
  const { slug } = useParams();

  useEffect(() => {
    if (slug) {
      getBlogComments(slug);
    }
  }, [slug]);

  const getBlogComments = (slug) => {
    open_api
      .get(`blog/pages/${slug}/comments/`)
      .then((res) => {
        setBlogReviews(res?.data?.data);
        setBlogMain(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertToEmbedUrl = (url) => {
    if (url.includes("youtube.com") || url.includes("youtu.be")) {
      const videoId = url.includes("youtube.com")
        ? url.split("v=")[1]
        : url.split("/").pop();
      const ampersandPosition = videoId?.indexOf("&");
      return ampersandPosition !== -1
        ? `https://www.youtube.com/embed/${videoId.substring(0, ampersandPosition)}`
        : `https://www.youtube.com/embed/${videoId}`;
    }

    return url;
  };

  const renderCustomEmbed = (block) => {
    if (!block.value || !block.value.embed_code) return null;

    // Sanitize and parse embed_code using dangerouslySetInnerHTML
    return (
      <div
        key={block.id}
        className={styles.customEmbed}
        dangerouslySetInnerHTML={{ __html: block.value.embed_code }}
      />
    );
  };

  const loadExternalScript = (src) => {
    const existingScript = document.querySelector(`script[src="${src}"]`);
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    loadExternalScript("https://www.instagram.com/embed.js");
    loadExternalScript("https://platform.twitter.com/widgets.js");
  }, []);

  return (
    <>
      <Helmet>
        <title>{data?.meta?.seo_title || data?.title}</title>
        <meta
          name="description"
          content={data?.meta?.search_description || data?.intro}
        />
      </Helmet>

      <ArticleHeader data={data} />
      <div className={styles.container}>
        {/* Header */}

        {/* Blog Content */}
        <div className={styles.content}>
          {data?.body?.map((block) => {
            switch (block.type) {
              case "h1":
                return <h1 key={block.id}>{block.value}</h1>;
              case "h2":
                return <h2 key={block.id}>{block.value}</h2>;
              case "paragraph":
                return (
                  <p
                    key={block.id}
                    dangerouslySetInnerHTML={{ __html: block.value }}
                  />
                );
              case "image_carousel":
                return (
                  <div key={block.id} className={styles.imageCarousel}>
                    {block.value.map((image, idx) => (
                      <img
                        key={idx}
                        src={image.url}
                        alt={`Carousel Image ${idx + 1}`}
                        className={styles.carouselImage}
                      />
                    ))}
                  </div>
                );
              case "thumbnail_gallery":
                return (
                  <div key={block.id} className={styles.thumbnailGallery}>
                    {block.value.map((image, idx) => (
                      <img
                        key={idx}
                        src={image.url}
                        alt={`Thumbnail Image ${idx + 1}`}
                        className={styles.thumbnailImage}
                      />
                    ))}
                  </div>
                );
              case "image_text":
                return (
                  <div key={block.id} className={styles.imageTextBlock}>
                    <img
                      src={block.value.image.url}
                      alt="Text with Image"
                      className={styles.imageTextImage}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: block.value.text,
                      }}
                      className={styles.imageTextContent}
                    />
                  </div>
                );
              case "video":
                return (
                  <div key={block.id} className={styles.videoContainer}>
                    <iframe
                      src={convertToEmbedUrl(block.value)}
                      title="Video Player"
                      width="560"
                      height="315"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className={styles.videoIframe}
                    ></iframe>
                  </div>
                );
              case "custom_embed":
                return renderCustomEmbed(block);
              default:
                return null;
            }
          })}
        </div>
      </div>
    </>
  );
}

export default BlogInfo;

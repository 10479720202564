import React, { useState } from "react";
import styles from "./index.module.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import mapIMg from "../../Assets/Logo_icons/mapImgdemo.png";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { open_api_without_auth } from "../../Utils/network";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AckModal from "../AckModal/AckModal";
import butgoLOgo from "../../Assets/butgoLOgo.png";
import RotatingText from "./RotatingText";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7A53FF",
    },
  },
});

function JoinUsForm() {
  const [contactInfo, setContactInfo] = useState([]);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    link: "",
    skills: "",
  });

  const [fromError, setFromError] = useState({
    name: ``,
    email: "",
    contact_number: "",
    join_as: "",
    link: "",
    skills: "",
    message: "",
  });

  const [firstNameError, setFirstNameError] = useState(false);
  // const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [linkError, setLinkError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const rotatingWords = ["EXPERTS", "CONTENT CREATOR", "BLOGGER"];

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const fieldValue =
      type === "radio" ? value : type === "checkbox" ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
    // Reset validation errors when user starts typing
    if (name === "first_name") {
      setFirstNameError(false);
    } else if (name === "email") {
      setEmailError(false);
    } else if (name === "phone") {
      setPhoneError(false);
    }
  };

  // Regular expressions for phone number and email validation
  const phoneRegex = /^\d{10}$/; // Assumes a 10-digit phone number format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const linksRegex =
    /^(https?:\/\/)?(www\.)?(facebook|twitter|linkedin|instagram|youtube)\.com\/[a-zA-Z0-9(\.\?\/)?]+$/;
  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform form submission or other actions with the form data
    setFirstNameError(false);
    // setLastNameError(false);
    setEmailError(false);
    setPhoneError(false);
    setLinkError(false);

    // Validate required fields
    if (formData.first_name === "") {
      setFirstNameError(true);
      return;
    }
    // if (formData.last_name === "") {
    //   setLastNameError(true);
    //   return;
    // }
    if (formData.email === "" || !emailRegex.test(formData.email)) {
      setEmailError(true);
      return;
    }
    if (formData.phone === "" || !phoneRegex.test(formData.phone)) {
      setPhoneError(true);
      return;
    }

    if (formData.link === "" || !linksRegex.test(formData.link)) {
      setLinkError(true);
      return;
    }

    const data = {
      name: `${formData?.first_name} ${formData?.last_name}`,
      email: formData?.email,
      contact_number: formData?.phone,
      join_as: formData?.subject,
      social_media_link: formData?.link,
      skills: formData?.skills,
      message: formData?.message,
    };
    // console.log(data, "posthit");
    // api_call.post("base/v1/contact_us/", formData)

    // Clear the form after submission
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      subject: "",
      skills: "",
      message: "",
    });

    // Reset the error state variables
    open_api_without_auth
      .post(`home/join_us/`, data)
      .then((res) => {
        // console.log(res);
        // if(res?.status === 400){
        //   setFromError({
        //     ...fromError,
        //     link: res?.data?.social_media_link
        //   })
        // }

        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          setFormData({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
            link: "",
          });
          handleClick();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setFirstNameError(false);
    // setLastNameError(false);
    setEmailError(false);
    setPhoneError(false);
    setLinkError(false);
  };

  return (
    <section className={styles.contactInfoContainer}>
      <RotatingText />
      <div className={styles.contactuseForm}>
        <div className={styles.leftContainer}>
          <div className={styles.iconText}>
            <span>
              <LocationOnIcon />
            </span>{" "}
            <strong> LOCATION:-</strong> B-303, Mangalya, Marol Maroshi Road,
            Andheri (East), Mumbai-400059 India.
          </div>
          <div className={styles.iconText}>
            <span>
              {" "}
              <EmailIcon />
            </span>{" "}
            <strong> EMAIL:-</strong>{" "}
            <a className={styles.joinusLink} href={`mailto: hello@myburgo.com`}>
              hello@myburgo.com
            </a>
          </div>
          <div className={styles.iconText}>
            <span>
              {" "}
              <LocalPhoneIcon />
            </span>{" "}
            <strong> CALL:-</strong>{" "}
            <a className={styles.joinusLink} href={`tel: +91 7710048212`}>
              +91 7710048212
            </a>
          </div>
          <div className={styles.mapContainer}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d391330.70977611555!2d72.27981986613713!3d19.06714795980473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c817241571e5%3A0x50bc1038d702a08!2sVel-Vin%20Packaging%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1721398641058!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>{" "}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <form>
            <div className={styles.inputFields}>
              <TextField
                name="first_name"
                label="First Name"
                value={formData.first_name}
                onChange={handleChange}
                variant="filled"
                required
                error={firstNameError}
                helperText={firstNameError ? "First Name is required" : ""}
                sx={{ width: "100%" }}
              />
            </div>
            <div className={styles.inputFields}>
              <TextField
                name="last_name"
                label="Last Name"
                value={formData.last_name}
                onChange={handleChange}
                // required
                variant="filled"
                // error={lastNameError}
                // helperText={lastNameError ? "Last Name is required" : ""}
                sx={{ width: "100%" }}
              />
            </div>
            <div className={styles.inputFields}>
              <TextField
                name="email"
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                variant="filled"
                required
                error={emailError}
                helperText={emailError ? "Email is required" : ""}
                sx={{ width: "100%" }}
              />
            </div>
            <div className={styles.inputFields}>
              <TextField
                name="phone"
                label="Phone Number"
                value={formData.phone}
                onChange={handleChange}
                variant="filled"
                required
                error={phoneError}
                helperText={phoneError ? "Phone Number is required" : ""}
                sx={{ width: "100%" }}
                inputProps={{ maxLength: 10 }}
              />
            </div>
            <div className={styles.inputFields}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Select Subject
              </FormLabel>
              <RadioGroup
                row
                name="subject"
                aria-labelledby="demo-radio-buttons-group-label"
                value={formData.subject}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Content Creator"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Experts"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="Blogger"
                />
              </RadioGroup>
            </div>
            <div className={styles.inputFields}>
              <TextField
                name="link"
                label="Social Media Link"
                value={formData.link}
                onChange={handleChange}
                variant="filled"
                required
                error={linkError}
                helperText={linkError ? "Enter your social media link" : ""}
                sx={{ width: "100%" }}
              />
            </div>
            <div className={styles.inputFields}>
              <TextField
                name="skills"
                label="SKILLS"
                value={formData.skills}
                onChange={handleChange}
                variant="filled"
                required
                // error={phoneError}
                // helperText={phoneError ? "Phone Number is required" : ""}
                sx={{ width: "100%" }}
              />
            </div>
            <div className={styles.inputFields}>
              <TextField
                name="message"
                label="Message"
                multiline
                rows={2}
                value={formData.message}
                onChange={handleChange}
                variant="filled"
                sx={{ width: "100%" }}
              />
            </div>
            <div className={styles.inputFields}></div>
            <Button
              onClick={handleSubmit}
              variant="contained"
              className={styles.submit}
            >
              SUBMIT
            </Button>
          </form>
        </div>
      </div>
      {/* <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Thank you for joining us!
        </Alert>
      </Snackbar> */}
      <AckModal handleClose={handleClose} data={data} open={open} />
    </section>
  );
}

export default JoinUsForm;
const data = {
  img: butgoLOgo,
  head: "Thank You",
  desc: "<p>Your submission has been received. We will be in touch and contact you soon!</p>",
};

import React from "react";
import styles from "./ExpertCards.module.css";
import porfile from "../../Assets/profile.png";
import star from "../../Assets/star.png";
import { Button } from "@mui/material";
import ReadMore from "../ReadMore/ReadMore";
import { useNavigate } from "react-router-dom";
function ExpertCards({ data }) {
  const navigate = useNavigate();

  const handleNavigatin = (id) => {
    navigate(`/experts/${id}`);
  };
  // console.log(data, "dataaaa");
  return (
    <>
      <div className={styles.cardContainer}>
        <div className={styles.left}>
          <div className={styles.imageContainer}>
            <img src={data.image}></img>
          </div>
          <Button
            onClick={() => handleNavigatin(data?.slug)}
            className={styles.Viewprofile}
            variant="text"
          >
            View Profile
          </Button>
        </div>

        <div className={styles.right}>
          <div>
            <div className={styles.top}>
              <div>
                <h1>
                  {data.first_name} {data.last_name}
                </h1>
                <p className={styles.experience}>2+ years of experience</p>
              </div>
              <div className={styles.rating}>
                <div>
                  {" "}
                  <img src={star}></img>
                </div>
                <span>4.7</span>
              </div>
            </div>

            <div className={styles.middle}>
              <ReadMore text={data.about} maxLength={200} />
              <div className={styles.labels}>
                {/* <span>Depression</span>
                            <span>Anxiety</span>
                            <span>Child Training</span>
                            <span>Behaviour</span> */}
                {data?.speciality_info?.slice(0, 4)?.map((v, i) => {
                  return <span>{v?.title}</span>;
                })}
              </div>
            </div>
          </div>

          <div className={styles.bottom}>
            <Button variant="contained" className={styles.bookButton}>
              Book Appointment
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.hideContainer}>
        <div className={styles.hideTop}>
          <div className={styles.profile}>
            <img src={data.image}></img>
          </div>
          <div className={styles.hideDesc}>
            <h1>
              {data.first_name} {data.last_name}
            </h1>
            <p>2+ years of experience</p>
            <div className={styles.stars}>
              <div>
                <img src={star}></img>
              </div>
              <span>4.7</span>
            </div>
          </div>
        </div>
        <div className={styles.HideMiddle}>
          <ReadMore text={data.about} maxLength={100} />
        </div>
        <div className={styles.hideButtons}>
          {/* <span>Depression</span>
                    <span>Anxiety</span>
                    <span>Child Training</span>
                    <span>Behaviour</span> */}
          {data?.speciality_info?.slice(0, 4)?.map((v, i) => {
            return <span>{v?.title}</span>;
          })}
        </div>
        <div className={styles.hideBottom}>
          <Button variant="contained" className={styles.bookButton}>
            Book Appointment
          </Button>
        </div>
        <div className={styles.hideView}>
          <Button variant="text" onClick={() => handleNavigatin(data?.slug)}>
            View Profile
          </Button>
        </div>
      </div>
    </>
  );
}

export default ExpertCards;
